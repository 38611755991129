import React from 'react';
import { Link } from 'gatsby';
import {Button}  from 'react-bootstrap';

const Pager = ({ pageContext }) => {

  const { previousPagePath, nextPagePath } = pageContext;
  return (
    <nav style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
      <div>
        {previousPagePath && (
          <Link to={previousPagePath}>
            <Button variant="outline-primary">← Newer Tutorials</Button>
          </Link>
        )}
      </div>

      <div style={{ justifySelf: 'flex-end' }}>
        {nextPagePath && (
          <Link to={nextPagePath}>
            <Button variant="outline-primary">Older Tutorials →</Button>
          </Link>
        )}
      </div>
    </nav>
  );
};

export default Pager;
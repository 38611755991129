import React from 'react'
import {Col}  from 'react-bootstrap'
import { Link } from 'gatsby'
import * as styles from '../styles/list-item.module.css'

export default function ArticlePreview({title, link,published, image, alt}) {
    return (
        <Col xs={12} md={4}>
            <div className={styles.item}>
                {published}
                <Link to={link} >
                    <div>{image &&   <img src={image} alt={alt}></img>}</div>
                    <h2>{title} </h2>
                </Link>                            
            </div>
        </Col>
    )
}
